// Dependencies.
import styled from 'styled-components'
import { useEffect, useContext, useState } from 'react'

import { CTA } from './CTA'
import { colors } from '../utils'
import { UserContext } from '../context'
import { P } from './P'

// Button.
const Button = styled(CTA)`
  align-items: center;
  color: ${colors.offBlack};
  display: flex;
  justify-content: center;
  width: 100%;

  .googleLogo {
    margin-right: 12px;
    width: 26px;
  }
`

const DIV = styled.div`
  .errorMessage {
    margin-left: 10px;
    margin-top: 5px;
    color: ${colors.red};
  }
`

// Props.
interface Props {
  onAuthSuccess: (token: string) => void
  onButtonClicked?: () => void
  onAuthError: (reason: string) => void
  buttonText: string
}

// Authenticate With Google.
export const AuthenticateWithGoogleButton: React.FC<Props> = (props) => {
  // Props
  const { onAuthSuccess, onButtonClicked, buttonText, onAuthError } = props

  const [gapiReady, SetGapiReady] = useState(false)
  const [error, setError] = useState('')

  const GOOGLE_POPUP_ERROR_MESSAGE =
    'Your browser blocked a popup required to continue any further. Please enable popups and try again.'

  let auth2: gapi.auth2.GoogleAuth = null
  const googleConfig: gapi.auth2.ClientConfig = {
    client_id:
      process.env.NEXT_PUBLIC_GOOGLE_AUTH_CLIENT_ID ||
      '487379500128-liuuln3mle6ellfsbg6mvtnbv0doseds.apps.googleusercontent.com',
    cookie_policy: 'single_host_origin',
    scope: 'profile email openid',
  }

  const googleAuthSuccess = async (googleUser: gapi.auth2.GoogleUser) => {
    // The ID token you need to pass to your backend:
    const idToken = googleUser.getAuthResponse().id_token
    onAuthSuccess(idToken)
  }

  const googleAuthFailure = (reason) => {
    console.log('googleAuthFailure reason: ' + JSON.stringify(reason))
    let errorMessage: string = JSON.stringify(reason)
    if (reason.error && reason.error.includes('popup_closed_by_user')) {
      errorMessage = GOOGLE_POPUP_ERROR_MESSAGE
    }

    if (onAuthError) onAuthError(errorMessage)
    setError(errorMessage)
  }

  const attachSignin = (element: any) => {
    auth2.attachClickHandler(element, {}, googleAuthSuccess, googleAuthFailure)
  }

  const onClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onButtonClicked) onButtonClicked()
    setError('')
  }

  useEffect(() => {
    const aScript = document.createElement('script')
    aScript.type = 'text/javascript'
    aScript.src = 'https://apis.google.com/js/api.js'

    document.head.appendChild(aScript)
    aScript.onload = () => {
      window.gapi.load('auth2', function () {
        auth2 = window.gapi.auth2.init(googleConfig)
        auth2.attachClickHandler(
          document.getElementById('customBtn'),
          {},
          googleAuthSuccess,
          googleAuthFailure,
        )
      })
    }
  }, [])

  // ..
  return (
    <DIV className="AuthenticateWithGoogleButton">
      <CTA id="customBtn" className="social-login" onClick={onClicked}>
        <img src="/images/googleLogo.png" /> {buttonText}
      </CTA>
      {/* <P className="detail errorMessage">{error}</P> */}
    </DIV>
  )
}
