// Imports
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'

// Props
interface TooltipProps {
  tooltip: string
}

// Components
export const InfoTooltip: React.FC<TooltipProps> = (props) => {
  const [isMounted, setIsMounted] = useState<boolean>(false)
  const [tooltipId, setTooltipId] = useState<null | string>(null)

  useEffect(() => {
    setTooltipId(
      `tooltip-${Math.round(
        tooltip.length * (Math.random() * (1337 - 1) + 1),
      )}`,
    )
    setIsMounted(true)
  }, [])
  const { tooltip } = props

  return isMounted ? (
    <DIV className="infoTooltip">
      <svg
        data-tip={tooltip}
        data-for={tooltipId}
        data-event-off="mouseleave click"
        width="18"
        height="18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M9 15A6 6 0 109 3a6 6 0 000 12z" fill="#A6A6A6" />
        <path
          d="M9 11.4V9M9 6.6h.008"
          stroke="#fff"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <ReactTooltip className="tooltip" id={tooltipId} />
    </DIV>
  ) : null
}

// Styled Components
const DIV = styled.div`
  &.infoTooltip {
    display: grid;
    place-items: center;

    & .tooltip {
      width: 245px;
      max-width: 100%;
    }
  }
`
