import { User } from '../types'

export function isBasicInfoComplete(user: User | null): boolean {
  if (user === null) {
    return false
  }
  const isValidFullName =
    typeof user.full_name === 'string' && user.full_name.length > 0
  const isValidTimeZone = user.time_zone !== null
  // const isZoomSetup = user.has_zoom_access_token
  return isValidFullName && isValidTimeZone
  // && isZoomSetup
}

export function isUserCreatedFirstEvent(user: User | null): boolean {
  if (user === null) {
    return false
  }
  return user.has_created_first_event
}

export function isUserSetupGroup(user: User | null) {
  if (user === null) {
    return false
  }
  return user.managed_groups.length > 0
}
