import React, { ChangeEventHandler, useState } from 'react'
import styled from 'styled-components'

import { colors, icons } from '../../utils'
import { P } from '../P'
import { Input as BaseInput } from './Input'

export const InputContainer = styled.div`
  position: relative;

  .visibilityIcon {
    position: absolute;
    top: 0;
    right: 8px;
    height: 100%;
    display: flex;
    align-items: center;
    img {
      height: 1.75rem;
    }
  }
`

const Input = styled(BaseInput)`
  padding: 12px 38px 12px 15px;
`

const ErrorContainer = styled.div`
  .errorMessage {
    color: ${colors.red};
    font-size: 14px;
    margin: 0px;
    margin-top: 8px;
  }
`

export const PasswordInput: React.FC<{
  errors?: string[]
  onChange: ChangeEventHandler<HTMLInputElement>
  placeholder?: string
  value: string
}> = ({ errors = [], onChange, placeholder = '', value }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)

  function togglePasswordVisibility() {
    setIsPasswordVisible((oldState) => !oldState)
  }
  return (
    <>
      <InputContainer>
        <Input
          className={errors.length > 0 ? 'inputError' : ''}
          onChange={onChange}
          placeholder={placeholder}
          type={isPasswordVisible ? 'text' : 'password'}
          value={value}
        />
        <i
          onClick={() => togglePasswordVisibility()}
          className="visibilityIcon">
          <img
            src={isPasswordVisible ? icons.eyeOpen : icons.eyeClose}
            aria-label={
              isPasswordVisible ? 'password visible' : 'password hidden'
            }
          />
        </i>
      </InputContainer>
      {errors.length > 0 && (
        <ErrorContainer className="errorContainer">
          {errors.map((error, index) => {
            return (
              <P key={index} className="detail errorMessage">
                {error}
              </P>
            )
          })}
        </ErrorContainer>
      )}
    </>
  )
}
