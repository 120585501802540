// Dependencies.
import styled from 'styled-components'

import { colors } from '../../utils'

// Input.
export const Input = styled.input`
  border-color: #f1f2f5;
  border-radius: 6px;
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  color: ${colors.offBlack};
  font-size: 17px;
  height: 45px;
  letter-spacing: -0.6px;
  line-height: 150%;
  outline: none;
  padding: 12px 15px;
  position: relative;
  width: 100%;

  &::placeholder {
    color: ${colors.grey};
  }

  &:focus {
    border-color: ${colors.washedPurple};
    border-width: 4px;
  }
`
