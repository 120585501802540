import { useRouter } from 'next/router'
import { useCallback, useContext, useEffect } from 'react'

import { UserContext } from '../context'
import { LOCALSTORAGE } from '../utils/localStorage'
import { NookEvent, User } from '../types'
import { usePaymentGateway } from './usePaymentGateway'
import {
  isBasicInfoComplete,
  isUserCreatedFirstEvent,
  isUserSetupGroup,
} from '../utils/profileValidation'
import { isUserFirstLogin } from '../utils/isUserFirstLogin'
import { useIsAuthorized } from './useIsAuthorized'
import { useSsrLocalStorage } from './useLocalStorage'

export const useRedirectIfAuthenticated = (path?: string) => {
  const router = useRouter()
  const { query } = router
  const { redirect } = query

  const { currentUser, loaded } = useContext(UserContext)

  const isAuthorized = useIsAuthorized()

  const { isSetupByUser: isUserSetupPayment } = usePaymentGateway()

  const [localeRedirect, setLocaleRedirect] = useSsrLocalStorage('redirect', '')

  const isUserCompleteOnboarding = useCallback(
    (user: User): boolean => {
      return (
        isBasicInfoComplete(user) &&
        isUserSetupGroup(user) &&
        isUserSetupPayment(user) &&
        isUserCreatedFirstEvent(user)
      )
    },
    [isUserSetupPayment],
  )

  useEffect(() => {
    if (isAuthorized) {
      if (loaded) {
        if (isUserFirstLogin(currentUser)) {
          router.replace(`/onboarding`)
        } else if (isUserCompleteOnboarding(currentUser)) {
          router.replace((redirect as string) || '/myevents')
        } else {
          if (router.query.redirect) {
            router.replace(router.query.redirect as string)
          } else {
            if (localeRedirect) {
              router.replace(localeRedirect)
              setLocaleRedirect('')
            }
            router.replace(`/dashboard`)
          }
        }
      }
    }
  }, [
    currentUser,
    isAuthorized,
    isUserCompleteOnboarding,
    loaded,
    path,
    redirect,
    router,
  ])
}

export const useRedirectIfNotAuthenticated = (path?: string) => {
  const router = useRouter()

  const { currentUser } = useContext(UserContext)
  const isAuthorized = useIsAuthorized()

  useEffect(() => {
    if (!isAuthorized) {
      const nextRoute = path || '/'
      const currentRoute = router.asPath
      if (nextRoute !== currentRoute) {
        router.replace(nextRoute)
      }
    }
  }, [currentUser, isAuthorized, path, router])
}

export const useUserRedirectIfEventNotOwned = (
  event: Partial<NookEvent>,
  user: User,
  path?: string,
) => {
  // Not perfect rejections but it will do for now.
  // - this should be in  get server side props but were stuck with this model for now

  const router = useRouter()

  useEffect(() => {
    if (user !== null && event !== null) {
      const userId = user?.id || -1
      const hostId = event?.host?.id || -2
      // console.log(hostId, userId)
      if (userId !== hostId && userId > 0 && hostId > 0) {
        router.replace(path || '/403')
      }
    }
  }, [event, user, path, router])
}
