// Dependencies.
import { useContext, useState } from 'react'
import FacebookLogin from '@greatsumini/react-facebook-login'
import styled, { css } from 'styled-components'

import { UserContext } from '../context'
import { CTA } from './CTA'
import { colors } from '../utils'

// Interfaces
interface Props {
  onAuthSuccess: (token: string) => void
  onButtonClicked?: () => void
  onAuthError: (reason: string) => void
  buttonText: string
}

// Components
export const AuthenticateWithFacebookButton: React.FC<Props> = (props) => {
  const { onAuthSuccess, onButtonClicked, onAuthError, buttonText } = props
  const [error, setError] = useState({ errorStatus: false, errorMessage: '' })

  const facebookID = process.env.NEXT_FACEBOOK_CLIENT_ID || '1531312450639628'

  const facebookAuthFailure = (reason: string) => {
    console.log('facebookAuthFailure reason: ' + reason)
    // if (onAuthError) onAuthError(JSON.stringify(reason))
    setError({ errorStatus: true, errorMessage: "Couldn't login to Facebook." })
  }

  const facebookClicked = (event: React.MouseEvent<HTMLDivElement>) => {
    if (onButtonClicked) onButtonClicked()
    setError({ errorStatus: false, errorMessage: '' })
  }

  return (
    <div className="AuthenticateWithFacebookButton">
      <FacebookLogin
        appId={facebookID}
        autoLoad={false}
        onSuccess={(response) => onAuthSuccess(response.accessToken)}
        onFail={(error) => facebookAuthFailure(error.status)}
        render={(renderProps) => (
          <div onClick={facebookClicked}>
            <CTA className="social-login" onClick={renderProps.onClick}>
              <img src="images/icon-auth-facebook.svg" /> {buttonText}
            </CTA>
          </div>
        )}
      />

      {/* {error.errorStatus ? <ErrorMessage>Error: {error.errorMessage}</ErrorMessage> : null} */}
    </div>
  )
}

// Styled components

const Button = styled(CTA)`
  align-items: center;
  color: ${colors.offBlack};
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;

  .facebookLogo {
    margin-right: 12px;
    width: 26px;
  }
`

const ErrorMessage = styled.div`
  padding: 10px 0;
  color: red;
  text-align: center;
`
