// Dependencies.
import styled from 'styled-components'

import { H5 as H5Base, P } from '../Headings'
import { show, colors } from '../../utils'
import { InfoTooltip } from '../Event/InfoTooltip'

const H5 = styled(H5Base)`
  color: ${colors.veryDarkGrayishBlue};
`
const Div = styled.div`
  .title {
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  .error-message {
    margin-top: 5px;
  }
  .titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  .infoIcon {
    margin-right: 8px;
    margin-left: 20px;
  }
`

// Props.
interface Props {
  className?: string
  required?: boolean
  style?: React.CSSProperties
  title: string
  tooltip?: string
  error?: string
}

// Field.
export const FormField: React.FC<Props> = (props) => {
  const {
    children,
    className,
    required,
    style,
    title,
    error,
    tooltip = '',
  } = props

  // ..
  return (
    <Div className={`formField ${className || ''}`} style={style}>
      {/* Name. */}
      <div className="titleContainer">
        <H5 className="title">
          {title}
          {required && <em>{' *'}</em>}
          {tooltip && (
            <div className="infoIcon">
              <InfoTooltip tooltip={tooltip} />
            </div>
          )}
        </H5>
      </div>

      {/* Input. */}
      {children}
      {show(error != undefined && error != null && error != '') && (
        <>
          <P className="warning error-message">{error}</P>
        </>
      )}
    </Div>
  )
}
